import "~/src/global"
import "~/src/polyfills"

import collapse from "@alpinejs/collapse"
import intersect from "@alpinejs/intersect"
import persist from "@alpinejs/persist"
import Alpine from "alpinejs"
import clipboard from "~/src/alpine/clipboard"
import { recipientFormData } from "~/src/alpine/components/recipientFormData"
import { recipientDeliveryData } from "~/src/alpine/components/recipientDeliveryData"
import hashRouter from "~/src/alpine/hashRouter"
import { initSidebarStore } from "~/src/alpine/sidebarStore"
import { defineTipDirective } from "~/src/alpine/tipDirective"

window.Alpine = Alpine

// Load plugins
window.Alpine.plugin(persist)
window.Alpine.plugin(collapse)
window.Alpine.plugin(intersect)
window.Alpine.plugin(clipboard)
window.Alpine.plugin(hashRouter)

// Load components
recipientFormData(window.Alpine)
recipientDeliveryData(window.Alpine)
initSidebarStore(window.Alpine)
defineTipDirective(window.Alpine)

window.Alpine.start()
