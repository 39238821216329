import { Nil, isNil } from "~/src/lib/any"
import type Alpine from "alpinejs"

type RouteFn = (hash: string) => boolean
type RouteType = string | RegExp | RouteFn | Nil

/**
 * Defines a `$route` Alpine magic method that accepts a route and returns true if
 * that route matches the hash fragment of the window location, false otherwise.
 * Routes can be:
 * - a string, which is matched literally
 * - a regex
 * - a predicate function taking the hash fragment string as its argument
 * - nothing, which returns true when the hash fragment string is empty
 *
 * TODO: regex matches do not yet capture and expose their match groups
 *
 * @example
 * <div x-show="$route("home")>
 *   Welcome home
 * </div>
 *
 * @example
 * <div x-show="$route(/product_id_\d+/)">
 *   View product
 * </div>
 *
 * @example
 * <div x-show="$route((fragment) => ["foo", "bar", "baz"].includes(fragment))">
 *  ...
 * </div>
 *
 * @example
 * <div x-show="$route()">
 *   Start here
 * </div>
 */
export default function (alpine: typeof Alpine) {
  alpine.store("hashrouter", window.location.hash.substring(1))

  window.addEventListener("hashchange", () => {
    alpine.store("hashrouter", window.location.hash.substring(1))
  })

  alpine.magic("route", () => {
    return (route: RouteType) => {
      const hash = alpine.store("hashrouter")?.toString() ?? ""
      if (typeof route == "string") {
        return route == hash
      }
      if (route instanceof RegExp) {
        return route.test(hash)
      }
      if (route instanceof Function) {
        return route(hash)
      }

      return hash.trim() == "" && isNil(route)
    }
  })
}
