import type Alpine from "alpinejs"
import { autoUpdate, computePosition, flip, shift, Placement } from "@floating-ui/dom"

type UpdateTipOptions = {
  placement?: Placement
}

const VALID_PLACEMENTS = new Set<Placement>([
  "top",
  "top-start",
  "top-end",
  "right",
  "right-start",
  "right-end",
  "bottom",
  "bottom-start",
  "bottom-end",
  "left",
  "left-start",
  "left-end",
])

function isPlacement(x: unknown): x is Placement {
  if (typeof x !== "string") return false

  return VALID_PLACEMENTS.has(x as Placement)
}

async function updateTip(tipee: HTMLElement, tip: HTMLElement, options?: UpdateTipOptions) {
  const { placement = "bottom" } = options ?? {}

  const { x, y } = await computePosition(tipee, tip, {
    placement: placement,
    middleware: [flip(), shift({ crossAxis: true })],
  })

  Object.assign(tip.style, {
    left: `${x}px`,
    top: `${y}px`,
  })
}

function autoUpdateTip(tipee: HTMLElement, tip: HTMLElement, options: UpdateTipOptions) {
  return autoUpdate(tipee, tip, () => updateTip(tipee, tip, options))
}

/**
 * Defines an `x-tip` Alpine directive that accepts an expression that evaluates to an HTMLElement.
 * The HTMLElement, tooltip, will be shown when the user hovers over the element that has the `x-tip` attribute.
 * When the tooltip is open, an `open` attribute will be added to the tooltip element.
 * The tooltip is headless save for its position.
 * Placement (right, left, top-right, etc) can be passed in as a modifier. For example, "x-tip.top".
 * The default is bottom. If multiple placements are passed, `x-tip` will use the last one.
 * @example
 * <button x-tip.top-start="$refs.toolTip">
 * <div x-ref="toolTip" class="absolute top-0 left-0 wtransition-opacity opacity-0 open:opacity-100">
 *  This is a tooltip
 * </div>
 */
export function defineTipDirective(alpine: typeof Alpine) {
  alpine.directive("tip", (el, { modifiers, expression }, { evaluateLater, effect, cleanup }) => {
    if (!(el instanceof HTMLElement)) {
      throw new Error("tip node must be an HTMLElement")
    }

    const placement = modifiers.filter(isPlacement).at(-1)
    const options = {}
    if (isPlacement(placement)) {
      options["placement"] = placement
    }

    // Creates a function that evaluates the expression passed to the `x-tip` attribute.
    const tipExp = evaluateLater(expression)

    // For purposes of cleaning up after ourselves, we need a shared reference to functions that are first
    // initialized in the effect callback, but are cleaned up outside in the cleanup callback.
    let cleanupTip: () => void
    let hide: (event: Event) => void
    let show: (event: Event) => void

    // effect is not properly typed
    effect(() => {
      tipExp((tip: unknown) => {
        if (tip instanceof HTMLElement) {
          cleanupTip = autoUpdateTip(el, tip, options)

          show = (event: Event) => {
            const { currentTarget } = event

            if (currentTarget instanceof HTMLElement) {
              updateTip(el, tip, options)
              tip.setAttribute("open", "")
            }
          }

          hide = (event: Event) => {
            const { currentTarget } = event

            if (currentTarget instanceof HTMLElement) {
              tip.removeAttribute("open")
            }
          }

          el.addEventListener("mouseenter", show)
          el.addEventListener("focus", show)
          el.addEventListener("mouseleave", hide)
          el.addEventListener("blur", hide)
        }
      })
    })

    cleanup(() => {
      el.removeEventListener("mouseenter", show)
      el.removeEventListener("focus", show)
      el.removeEventListener("mouseleave", hide)
      el.removeEventListener("blur", hide)

      cleanupTip?.()
    })
  })
}
