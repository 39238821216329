import Alpine from "alpinejs"
import { Address } from "~/src/types/address"
import { camelCaseKeys } from "~/src/lib/object"

type DataProps = {
  displayAddressCollection: boolean
  locationEnabled: boolean
  locationId: string
  locations: { [index: string]: Address }
  name: string
  email: string
  address: Address
  federalTaxIdCountries: { [index: string]: string }
  attestationGiven: boolean
  userSelectedRecommendedAddress: boolean
  userConfirmed: boolean
}

type DataObject = DataProps & {
  chooseAddressModal: boolean
  draftAddress: Address
  locationSelected: boolean
  onChangeLocation: () => void
  locationReadonly: { ":class"(): string; ":readonly"(): boolean }
  locationDisabled: { ":class"(): string; ":disabled"(): boolean }
}

export function recipientDeliveryData(alpine: typeof Alpine) {
  alpine.data("orderRecipientDelivery", function (defaultCountry: string, chooseAddressModal: boolean): DataObject {
    const props = camelCaseKeys(JSON.parse(this.$el.dataset.props)) as DataProps
    props.address.addressCountry = defaultCountry
    return {
      ...props,
      chooseAddressModal,
      locationSelected: props.locations[props.locationId] != null,
      draftAddress: { ...props.address },
      locationReadonly: {
        [":class"]() {
          return this.locationSelected ? "text-gray-400" : ""
        },
        [":readonly"]() {
          return this.locationSelected
        },
      },
      locationDisabled: {
        [":class"]() {
          return this.locationSelected ? "text-gray-400" : ""
        },
        [":disabled"]() {
          return this.locationSelected
        },
      },
      onChangeLocation() {
        const oldLocationSelected = this.locationSelected
        const newLocationSelected = this.locations[this.locationId] != null

        if (!oldLocationSelected) {
          this.draftAddress = { ...this.address }
        }

        if (newLocationSelected) {
          this.address = { ...this.locations[this.locationId] }
        } else {
          this.address = { ...this.draftAddress }
        }

        this.locationSelected = newLocationSelected
      },
    }
  })
}
